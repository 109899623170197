import React from "react";
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import AdminRouter from "./components/admin/AdminRouter";
const App = () => {
 return (
  <>

    <Router>
        <Routes>
          <Route index path="/" element={<Home />} />
          {/* <Route path="/admin/*" element={<AdminRouter />} /> */}
        </Routes>
    </Router>
    </>
 );
};

export default App;