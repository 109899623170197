import { getLink } from "../../sdk";
import AdComponent from "../ads/GoogleAds";
import Header from "../header/Header";
import React, { useEffect, useState } from "react";
import {RiShareBoxFill} from 'react-icons/ri';

const Home = () => {
    const [title, setTitle] = useState('')
    const [k, setLink] = useState('')

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {

    //             const response = await getLink();
    //             setTitle(response.title)
    //             setLink(response.link)
    //         } catch (exception) {
    //         }
    //     }
    //     fetchData()
    // }, [])

    const ShowLink = () => {
        return (
            <>
            <p>{title}&nbsp;<a href={k} target="_blank" rel="noreferrer">here&nbsp;<RiShareBoxFill /></a></p>
            </>
        )
    }

    return (
        <>
            <Header />
            <div className="container">
                <div className="centered-element">

                { title && k && <ShowLink /> }
                <p >This website is under construction.</p>
                <p >Contact: <a href="mailto:support@valent.me">support@valent.me</a></p>
                </div>
            </div>
        </>
    );
};

export default Home;