import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Header.css";

const Header = () => {
 const [showMenu, setShowMenu] = useState(false);
 const [isLoggedIn, setIsLoggedIn] = useState(false);
 
 useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
 }, [])
 const toggleMenu = () => {
   setShowMenu(!showMenu);
 };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };
  
 return (
  <>
   <header className="header">
     <nav className="nav container">
       <NavLink to="/" className="nav__logo">
         <img src="logo.png" />
       </NavLink>
     { isLoggedIn && 
       <NavLink to="/logout" className="nav__logo">
          <p className="logout-btn">Log out </p>
       </NavLink>
}
     </nav>
   </header>
  </>
 );
};
export default Header;